import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyCKN4Q8-Q5fziUKqIsaFte2xHuH_NkFV3g",
  authDomain: "agape-signup.firebaseapp.com",
  databaseURL: "https://agape-signup.firebaseio.com/",
  projectId: "agape-signup"
});

export default firebase;
