import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core";

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      maxWidth: 752,
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
    demo: {
      backgroundColor: theme.palette.background.paper
    },
    title: {
      margin: theme.spacing(4, 0, 2)
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    heading: {
       color: '#0d47a1',
       fontFamily: 'Roboto', 
    },
    h5: {
      color: '#1976d2',
      textAlign: 'center',
      fontFamily: 'Roboto',
    }
}));


export const DonateScreen = ()  => {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}><Typography variant="h2" className={classes.heading}>Please consider financially supporting us</Typography></Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}><Typography variant="h4" className={classes.heading}>Your donation will allow our ministry and service to continue and grow:</Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>Serving the migrant community by providing food, clothing, and community support while building personal relationships</Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>Teaching about Catholic social justice, especially in response to issues affecting the migrant community</Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>Inspiring lifelong commitments to service</Typography></Paper>
      </Grid>
    </Grid>
    <Grid>
      <Grid container spacing={3} direction-xs-colum={"column"}>
      <Grid item xs={8} sm={4}>
        <Paper className={classes.paper}><img src='https://firebasestorage.googleapis.com/v0/b/agape-signup.appspot.com/o/85009299_1237720396437688_6579182688157040640_o.jpg?alt=media' alt="Group Image" height="200"/></Paper>
      </Grid>
      <Grid item xs={8} sm={4}>
        <Paper className={classes.paper}><img src='https://firebasestorage.googleapis.com/v0/b/agape-signup.appspot.com/o/agapelogo.jfif?alt=media' alt="Logo" height="200" class="center"/></Paper>
      </Grid>
      <Grid item xs={8} sm={4}>
        <Paper className={classes.paper}><img src='https://firebasestorage.googleapis.com/v0/b/agape-signup.appspot.com/o/grey.jpg?alt=media' alt="Fun Group!" height="200"/></Paper>
      </Grid>
      </Grid>
      </Grid>
      <div className={classes.root}>
    <a href='https://donate.seattlearchdiocese.org/agape-gift'><Button variant="contained" color="primary">
        Donate
      </Button></a>
    </div>
    </div>
    
  );
  
};





