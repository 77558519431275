import React from "react";
import {observer} from "mobx-react";
import {Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import SignIn from "../../components/signIn";
import MoreInfo from "../../components/moreInfo";
import {viewStore} from "../../store/signInStore";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heading: {
     color: '#0d47a1',
     fontFamily: 'Roboto', 
  },
  h5: {
    color: '#1976d2',
    textAlign: 'center',
    fontFamily: 'Roboto',
  }
  
}));

function generate(element) {
  return [0, 1, 2, 3, 4].map(value =>
    React.cloneElement(element, {
      key: value
    })
  );
}

export const HomeScreen = observer(() => {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <div>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}><Typography variant="h2" className={classes.heading}>Welcome to Agape</Typography></Paper>
      </Grid>
      <Grid item xs={8} sm={4}>
        <Paper className={classes.paper}><SignIn></SignIn> </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}><Typography variant="h4" className={classes.heading}>Who We Are</Typography></Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}><Typography variant= "h5" className={classes.h5}>Agape Mission: The Agape Service Project fosters service, community, and prayer as participants encounter Christ and uphold the dignity of our migrant brothers and sisters.</Typography></Paper>
      </Grid>
      <Grid container spacing={3} direction-xs-colum={"column"}>
      <Grid item xs={8} sm={4}>
        <Paper className={classes.paper}><img src='https://firebasestorage.googleapis.com/v0/b/agape-signup.appspot.com/o/kelsey.jpg?alt=media' alt="Group Image" height="200"/></Paper>
      </Grid>
      <Grid item xs={8} sm={4}>
        <Paper className={classes.paper}><img src='https://firebasestorage.googleapis.com/v0/b/agape-signup.appspot.com/o/agapelogo.jfif?alt=media' alt="Logo" height="200" class="center"/></Paper>
      </Grid>
      <Grid item xs={8} sm={4}>
        <Paper className={classes.paper}><img src='https://firebasestorage.googleapis.com/v0/b/agape-signup.appspot.com/o/group.jfif?alt=media' alt="Fun Group!" height="200"/></Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}><Typography variant="h4" className={classes.heading}>Agape Goals</Typography></Paper>
      </Grid>
    </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>To serve the migrant community of Whatcom County by providing food, clothing, and community support with the ultimate goal of building personal relationships with those we serve</Typography></Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>To teach participants about Catholic social justice, especially in response to issues affecting the migrant community</Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>To inspire and encourage participants to continue answering the call to service throughout their lives</Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>To create a space where participants can grow in their faith and meet Jesus through prayer, people, and service</Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography variant= "h6" className={classes.h5}>To do everything with agape love</Typography></Paper>
      </Grid>
    </Grid>
      
      {viewStore.isNewUser ? <MoreInfo></MoreInfo> : null}
      
  <Typography variant="h4">Contact Us</Typography>
  <Typography><b>Kelsey Harrington</b></Typography>
  <Typography>Director, Agape Service Project</Typography>
  <Typography>(425) 941-2042 </Typography>
  <Grid container spacing={3}>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography>Email <a href='mailto://kelsey.harrington@seattlearch.org'>Kelsey</a></Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography>Visit our <a href='https://agapeserviceproject.blogspot.com'>Blog</a> for weekly updates </Typography></Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}><Typography>Follow us on <a href='https://facebook.com/AgapeServiceProject'>Facebook</a></Typography></Paper>
      </Grid>
    </Grid>
    
  </div>
  );
});