import React from "react";
import firebase from "../services/firebase";
import {observable, decorate, toJS, action} from "mobx";

let db = firebase.firestore();
var today = new Date();
var notBeforeDate = (today.getMonth()+1)+'/'+ today.getDate() + '/' + today.getFullYear();

class EventStore {
  events = [];
  selectedEvents = [];

  constructor() {
    console.log("EventStore called");
    db.collection("events")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          this.events.push(doc.data());
        });
        console.log("loadSync done");
      })
      .catch(err => {
        console.log("Error getting documents", err);
      });
  }

  async loadUserEvents(user) {
    this.selectedEvents = [];
    if (user != null) {
      console.log("here");

      await db.collection("userevents")
        .doc(user.uid)
        .get()
        .then(doc => {
          console.log(doc.data());
          for (var i in doc.data()) {
            let selectedEvent = doc.data()[i];
            let arrOfStr = selectedEvent.split("_");

            if ((arrOfStr.length == 2) && (arrOfStr[1] >= notBeforeDate)) {
              this.selectedEvents.push(doc.data()[i]);
            }
          }
        })
        .catch(err => {
          console.log("Error getting userevents documents", err);
        });
      console.log(this.selectedEvents);
    }
  }

  saveSelectedEvents(user, selectedEvents) {
    console.log("saveSelectedEvents uid: ", user.uid);
    this.selectedEvents = selectedEvents;

    db.collection("userevents")
      .doc(user.uid)
      .set(Object.assign({}, selectedEvents))
      .then(function() {
        console.log("Document successfully written!");
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  }
}

decorate(EventStore, {
  events: observable,
  loadUserEvents: action.bound
});

export default EventStore;

export const eventStore = new EventStore();
