import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import {HomeScreen} from "../screens/homeScreen";
import {VolunteerScreen} from "../screens/volunteerScreen";
import {DashboardScreen} from "../screens/dashboardScreen"
import {DonateScreen} from "../screens/donateScreen";
import NavBar from "../components/navbar";

function Routes(props) {
  return (
    <Router>
      <div>
        <nav>
          <NavBar></NavBar>
        </nav>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path={"/volunteer"} component={VolunteerScreen} />
          <Route exact path={"/dashboard"} component={DashboardScreen} />
          <Route exact path={"/donate"} component={DonateScreen} />
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </div>
    </Router>
  );
}

export default Routes;
