import React from "react";
import firebase from "../services/firebase";
import {observable, action, decorate, computed, reaction} from "mobx";

let db = firebase.firestore();

class SignInStore {
  user = null;
  userInfo = "";
  isNewUser = false;

  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      this.setUser(user);
    });
    this.load();
    const firebaseReaction = reaction(
      () => this.user,
      () => {
        this.save();
      }
    );
  }

  load() {
    if (localStorage.getItem("FirebaseUser")) {
      this.setUser(JSON.parse(localStorage.getItem("FirebaseUser")));
    }
  }

  save() {
    localStorage.setItem("FirebaseUser", JSON.stringify(this.user));
  }

  get isLoggedIn() {
    return this.user !== null;
  }

  logout() {
    firebase.auth().signOut().then(function() {
      window.location = '/';
      this.setUser(null);
    }).catch(function(error) {
      // An error happened.
    });

  }

  setUser(user) {
    console.log("setUser called");
    this.user = user;
    console.log(this.user);

    if (this.user != null) {
      this.isNewUser = false; // assume we do not need additional user data until we know for sure

      console.log("setUser query uid: ", this.user.uid);

      let userRef = db.collection("users").doc(this.user.uid);
      let getDoc = userRef
        .get()
        .then(doc => {
          if (!doc.exists) {
            console.log("setUser query email not found");
            this.isNewUser = true;
          } else {
            console.log("setUser query email found");
            this.isNewUser = false;
          }
        })
        .catch(err => {
          this.isNewUser = true;
          console.log("Error getting document", err);
        });
    }
  }

  async loadUserInfo() {
    if (this.user != null) {
      await db.collection("users")
        .doc(this.user.uid)
        .get()
        .then(doc => {
          this.userInfo = doc.data();
        })
        .catch(err => {
          console.log("Error getting userevents documents", err);
        });
    }
  }  

  saveUserInfo(firstName, lastName, phone, spanishSpeaker) {
    if (this.isLoggedIn) {
      console.log(
        firstName + " " + lastName + " " + phone + " " + spanishSpeaker
      );

      // Add a new document in collection "users"
      console.log("saveUserInfo uid: ", this.user.uid);

      db.collection("users")
        .doc(this.user.uid)
        .set({
          uid: this.user.uid,
          email: this.user.email,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          spanishSpeaker: spanishSpeaker
        })
        .then(function() {
          console.log("Document successfully written!");
          //this.isNewUser = false;
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
          //this.isNewUser = false; // failed to write to the database, don't ask the user to save agian
        });
    } else {
      console.error("Error: can't save data when user is not logged in");
    }
  }

  async forgetUserInfo() {
    if (this.isLoggedIn) {
      console.log("Forget uid: ", this.user.uid);
      this.userInfo="";

      await db.collection("users")
        .doc(this.user.uid).delete()
        .then(function() {
          console.log("Document successfully deleted!");
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
        });

      this.logout();
    } else {
      console.error("Error: can't save data when user is not logged in");
    }
  }
}


decorate(SignInStore, {
  user: observable,
  setUser: action.bound,
  isLoggedIn: computed,
  isNewUser: observable, // the user needs to provide personal info (it will be logged on the server)
  userInfo: observable,
  saveUserInfo: action.bound,
  logout: action.bound,
  save: action.bound
});

export default SignInStore;

// TODO: name 'viewStore' is not good.  Shouldn't this be signInStore?
export const viewStore = new SignInStore();
export const Context = React.createContext(SignInStore);
