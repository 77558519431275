import React, {useEffect} from "react";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import {lighten, makeStyles} from "@material-ui/core/styles";
import {
  TextField,
  FormGroup,
} from "@material-ui/core";

//TODO: change the way this is imp
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import SignIn from "../../components/signIn";
import {viewStore} from "../../store/signInStore";
import {eventStore} from "../../store/eventStore";

var today = new Date();
var notBeforeDate = (today.getMonth()+1)+'/'+ today.getDate() + '/' + today.getFullYear();
console.log(today);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {id: "date", numeric: false, disablePadding: false, label: "Date"},
  {id: "time", numeric: true, disablePadding: false, label: "Time"},
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Volunteer Opportunities"
  },
  {id: "location", numeric: false, disablePadding: false, label: "Location"},
  {id: "notes", numeric: false, disablePadding: false, label: "Notes"}
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            //key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const EnhancedUserInfo = props => {
  const classes = useStyles();
  const {numSelected} = props;
  const [speaksSpanish, setSpeaksSpanish] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleSave = () => {
    viewStore.saveUserInfo(firstName, lastName, phoneNumber, speaksSpanish);
  };

  const handleForget = () => {
    viewStore.forgetUserInfo();
  };

  async function DoFillForm() {
    // get the list of user selected events
    await viewStore.loadUserInfo();
    if (!viewStore.isNewUser) {
      console.log(viewStore.userInfo);
      console.log("phone " + viewStore.userInfo["phone"]);
      console.log("spanishSpeaker " + viewStore.userInfo["spanishSpeaker"]);
      console.log("firstName " + viewStore.userInfo["firstName"]);
      console.log("lastName " + viewStore.userInfo["lastName"]);
      setSpeaksSpanish(viewStore.userInfo["spanishSpeaker"]);
      setFirstName(viewStore.userInfo["firstName"]);
      setLastName(viewStore.userInfo["lastName"]);
      setPhoneNumber(viewStore.userInfo["phone"]);
    }
  }


  useEffect(() => {
    DoFillForm();
  }, []);

  if (viewStore.isLoggedIn) {
    return (
      <div>
        <Typography>Your information:</Typography>
        <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="given-name"
          label="First Name"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <TextField
          id="family-name"
          label="Last Name"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
        <TextField
          id="tel"
          label="Phone Number"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
      </form>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={speaksSpanish}
              onChange={() => setSpeaksSpanish(!speaksSpanish)}
              value="spanish"
            />
          }
          label="Speak Spanish"
        />
      </FormGroup>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSave()}
      >
        Save
      </Button>
      <p/>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleForget()}
      >
        Forget Me
      </Button>
      <p/>
      <p/>
      {(numSelected==0)?<Typography>No events selected.  Select events in the volunteer screen.</Typography>:<Typography>Your events:</Typography>}
      <p/>
      </div>);
    }
    else{
      return (<SignIn></SignIn>);
    }
  }

  EnhancedUserInfo.propTypes = {
    numSelected: PropTypes.number.isRequired
  };
  

export const DashboardScreen = observer(() => {
  const classes = useStyles();

  const rows = eventStore.events;
  const [selected, setSelected] = React.useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  
  async function DoSelection() {
    // get the list of user selected events
    await eventStore.loadUserEvents(viewStore.user);
    setSelected(eventStore.selectedEvents);
  }

  useEffect(() => {
    DoSelection();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <EnhancedUserInfo numSelected={selected.length}/>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={selected.length}
            />
            <TableBody>
              {
              stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  if (row.date >= notBeforeDate) {
                    const rowKey = row.name + "_" + row.date;
                    const isItemSelected = isSelected(rowKey);
                    if (isItemSelected) {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={rowKey}
                        >
                          <TableCell
                            component="th"
                            align="left"
                            scope="row"
                            padding="none"
                          >
                            {row.date}
                          </TableCell>
                          <TableCell align="right">{row.time}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.location}</TableCell>
                          <TableCell align="left">{row.notes}</TableCell>
                        </TableRow>
                      );
                    }
                    else {
                      return;
                    }
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
});
