import React from "react";
import {observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Link} from "react-router-dom";
import {viewStore} from "../../store/signInStore";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default observer(() => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Home" value={"/"} component={Link} to={"/"} />
          <Tab
            label="Volunteer"
            value={"/volunteer"}
            component={Link}
            to={"/volunteer"}
          />
          <Tab
            label="My Dashboard"
            value={"/dashboard"}
            component={Link}
            to={"/dashboard"}
          />
          <Tab
            label="Donate"
            value={"/donate"}
            component={Link}
            to={"/donate"}
          />
          {viewStore.isLoggedIn ? (
            <Tab
              style={{position: "absolute", right: 0}}
              label="Logout"
              value={"/logout"}
              component={Link}
              to={"/"}
              onClick={() => viewStore.logout()}
            />
          ) : null}
        </Tabs>
      </AppBar>
    </div>
  );
});
