import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Modal,
  FormControlLabel
} from "@material-ui/core";

import {viewStore} from "../../store/signInStore";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

export default function MoreInfo() {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);
  const [speaksSpanish, setSpeaksSpanish] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleSkip = () => {
    setOpen(false);
  };

  const handleSave = () => {
    viewStore.saveUserInfo(firstName, lastName, phoneNumber, speaksSpanish);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleSkip}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">We Need a Little More Info...</h2>
          <Typography>You're new!</Typography>
          <Typography>Tell us about yourself: </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="given-name"
              label="First Name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <TextField
              id="family-name"
              label="Last Name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
            <TextField
              id="tel"
              label="Phone Number"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </form>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={speaksSpanish}
                  onChange={() => setSpeaksSpanish(!speaksSpanish)}
                  value="spanish"
                />
              }
              label="Speak Spanish"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleSkip()}
          >
            Skip
          </Button>
          <p></p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
}
