import React, {useEffect} from "react";
import {StyledFirebaseAuth} from "react-firebaseui";
import {observer} from "mobx-react";
import Typography from "@material-ui/core/Typography";
import {viewStore} from "../../store/signInStore";
import firebase from "../../services/firebase";

const SignIn = observer(() => {
  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [ 
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          prompt: 'select_account'
        }
      },
  ],
    signInSuccessUrl: "/",
    callbacks: {
      signInSuccess: false
    }
  };

  const loginButton = () => {
    if (!viewStore.isLoggedIn) {
      return (
        <>
          <Typography>You Are Not Logged In!</Typography>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          ></StyledFirebaseAuth>
        </>
      );
    } else {
      return <Typography>Welcome {viewStore.user.displayName}!</Typography>;
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      viewStore.setUser(user);
    });
  }, []);

  return <div className="Login">{loginButton()}</div>;
});
export default SignIn;
